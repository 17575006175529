import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/icontel.png";

const CtaTel = (props) => {
  const [text, setText] = useState("(71) 99163-7821");

  useEffect(() => {
    if (props.text !== undefined) {
      setText(props.text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button id="ctaTel">
      <a href="tel:991637821">
        <figure>
          <img src={icon} alt="Ícone do Telefone" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaTel;
