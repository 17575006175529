import React from "react";
import "./styles.css";

import logo_topo from "../../assets/logo_topo.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <figure className="left">
          <img src={logo_topo} alt="Logo" />
        </figure>

        <article className="right">
          <a href="#about2">
            <p className="text">Ver unidades</p>
          </a>
        </article>
      </section>
    </nav>
  );
};

export default Menu;
