import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure>
            <img src={logo_rodape} alt="Logo" />
          </figure>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            SOS Sobriedade Brasil - Tratamento Dependência Química - Todos os
            direitos reservados
          </p>
        </div>
      </div>

      <div className="footer3">
        <div className="container">
          <p className="text">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
