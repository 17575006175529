import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";
import header3_4 from "../../assets/header3_4.png";

import CtaWpp from "../../components/CTAWpp";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header3_1,
  },
  {
    id: 2,
    image: header3_2,
  },
  {
    id: 3,
    image: header3_3,
  },
  {
    id: 4,
    image: header3_4,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container vertical">
          <h1 className="title">
            <b>S.O.S</b> Sobriedade Brasil
          </h1>

          <p className="text">
            Centro Terapêutico especializado em tratamento de pessoas com
            dependência química - álcool, drogas e transtornos decorrente do uso
            de substâncias. 
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">
            Metodologia e Abordagem Motivacional. Há 10 anos restaurando vidas!
             
          </h2>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <div className="left">
            <h2 className="title">Sobre Nós</h2>

            <Swiper
              className="mobile"
              spaceBetween={20}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 1
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <p className="text">
              Somos um Centro Terapêutico especializado em reabilitar pessoas
              com dependência química. Contamos com 3 unidades, sendo 1 em
              Salvador e 2 em Camaçari - BA, todas com estrutura completa para
              receber até 60 pacientes. Além de profissionais dedicados e
              comprometidos em realizar um trabalho humanizado e de excelência
              na recuperação de nossos pacientes.
            </p>

            <CtaWpp />
          </div>

          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 1
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </article>
      </section>
    </header>
  );
};

export default Header;
