import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";
import CtaTel from "../../components/CTATel";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about1_6 from "../../assets/about1_6.png";
import about1_7 from "../../assets/about1_7.png";
import about1_8 from "../../assets/about1_8.png";
import about1_9 from "../../assets/about1_9.png";
import about1_10 from "../../assets/about1_10.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about4 from "../../assets/about4.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: about1_1,
  },
  {
    id: 2,
    image: about1_2,
  },
  {
    id: 3,
    image: about1_3,
  },
  {
    id: 4,
    image: about1_4,
  },
  {
    id: 5,
    image: about1_5,
  },
  {
    id: 6,
    image: about1_6,
  },
  {
    id: 7,
    image: about1_7,
  },
  {
    id: 8,
    image: about1_8,
  },
  {
    id: 9,
    image: about1_9,
  },
  {
    id: 10,
    image: about1_10,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 1
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="right">
            <h2 className="title">Nossa Estrutura</h2>

            <Swiper
              className="mobile"
              spaceBetween={20}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 1
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <p className="text">
              Oferecemos uma estrutura completa, com lazer, dormitórios,
              alimentação regular e saldável. Tudo para acolher nossos pacientes
              de forma digna e confortável, promovendo assim uma recuperação
              mais ágil e eficaz.
            </p>

            <CtaWpp />
          </div>
        </div>
      </article>

      <article className="about2" id="about2">
        <div className="container vertical">
          <h2 className="title">Nossas Unidades</h2>

          <p className="text">
            Nossas unidade possuem um espaço de vivência amplo, com área de
            lazer, piscina, dormitórios, refeitórios e muito mais!
          </p>

          <p className="text2">
            Agende uma visita à unidade mais próxima de você!
          </p>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={about2_1} alt="Unidade 1 - MATRIZ - Salvador" />
              </figure>

              <p className="item_title">Unidade 1 - MATRIZ - Salvador </p>

              <p className="item_text">
                Rua Rancho Alegre, nº 31, Bairro Nova Esperança - Salvador - BA.
                Cia Aeroporto
              </p>

              <CtaWpp />
            </div>

            <div className="item">
              <figure>
                <img src={about2_2} alt="Unidade 2 - Camaçari" />
              </figure>

              <p className="item_title">Unidade 2 - Camaçari </p>

              <p className="item_text">Camaçari Cascalheira - BA.</p>

              <CtaWpp />
            </div>

            <div className="item">
              <figure>
                <img src={about2_3} alt="Unidade 3 - Camaçari" />
              </figure>

              <p className="item_title">Unidade 3 - Camaçari </p>

              <p className="item_text">Camaçari Jacuípe - BA.</p>

              <CtaWpp />
            </div>
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">
            Inscreva seu familiar em nosso programa de tratamento
          </h2>

          <p className="text">Fale conosco via telefone ou WhatsApp:</p>

          <div className="cta">
            <CtaWpp text={"(71) 99163-7821"} />
            <CtaTel />
          </div>
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <h2 className="title">FORMAS DE PAGAMENTO</h2>

          <p className="text">crédito, débito, ESPÉCIE e PIX</p>

          <figure>
            <img src={about4} alt="FORMAS DE PAGAMENTO" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
